.layout,
.ui.container.layout {
	flex: 0 0 auto;
	display:flex;
}
.layout.column {
	flex-direction: column;
	align-items:stretch;
}
.layout.top {
	align-items: flex-start;
}
.layout.vcenter {
	align-items: center;
}
.layout.vbaseline {
	align-items: baseline;
}
.layout.vstretch {
	align-items: stretch;
}
.layout.bottom {
	align-items: flex-end;
}
.layout.left {
	justify-content: flex-start;
}
.layout.hcenter {
	justify-content: center;
}
.layout.hbetween {
	justify-content: space-between;
}
.layout.haround {
	justify-content: space-around;
}
.layout.right {
	justify-content: flex-end;
}
.layout.column > img,
.layout.column.left > img {
	align-self:flex-start;
}
.layout.column.hcenter > img {
	align-self:center;
}
.layout.column.right > img {
	align-self:flex-end;
}

.layout.fullwidth {
	width:100%;
}

.inverted.layout {
	background: rgba(0, 0, 0, 0.8);
}

.inverted.layout p {
	color: white;
}

