element-carousel {
	display:block; /* mandatory for custom elements */
	position:relative;
	width:100%;
}
element-carousel .flickity-page-dots li.dot {
	pointer-events:auto; /* workaround prosemirror.css <= 0.24 style */
}
element-carousel .flickity-page-dots .dot {
	box-shadow: 0 0 3px white;
}
.inverted element-carousel .flickity-page-dots .dot {
	background:#CCC;
}

element-carousel-cell {
	display:block;
	background: transparent;
	min-height:2em;
	display:flex;
	align-items:center;
	flex-direction:column;
	justify-content:center;
}

element-carousel-cell > .media {
	width:100%;
	height:100%;
}

element-carousel-cell > .content {
	position: absolute;
	width:100%;
	height:100%;
}

.ProseMirror element-carousel-cell > .content {
	pointer-events:none;
}
.ProseMirror element-carousel-cell > .content > * {
	pointer-events:auto;
}

body.fullview {
	overflow:hidden;
}

body.fullview element-gallery[block-content="galleries"] > :not(element-carousel) {
	display:none;
}
body.fullview element-carousel.fullview {
	display:block;
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	height:100%;
	background-color:black;
	z-index:1002;
}
body.fullview element-carousel.fullview element-carousel-cell {
	height:100vh !important;
}

element-carousel > .ui.icon.button.fullview {
	display:block;
	position:absolute;
	top: 10px;
	right: 10px;
	background:transparent;
	padding: 0;
	margin: 0;
	margin-left: -22px;
}
element-carousel[data-prev-next-buttons="true"] > .ui.icon.button.fullview {
	right:auto;
	left:50%;
}
element-carousel:not([data-fullview-button="true"]) > .ui.icon.button.fullview {
	display:none;
}
element-gallery element-carousel:not([data-fullview-button="true"]) > .ui.icon.button.fullview {
	display:block;
}
element-carousel > .ui.icon.button.fullview > .icon {
	display:none;
}
element-carousel > .ui.icon.button.fullview > .icon:before {
	filter: contrast(0.7);
}
body:not(.fullview) element-carousel > .ui.icon.button.fullview > .expand.icon {
	display:inline-block;
}
body.fullview element-carousel.fullview > .ui.icon.button.fullview > .close.icon {
	display:inline-block;
}
element-gallery element-carousel > .ui.icon.button.fullview > .expand.icon {
	display:none !important;
}
element-gallery element-carousel > .ui.icon.button.fullview > .close.icon {
	display:inline-block;
}
i.expand.icon:before {
	background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"/></svg>');
	content:'';
}
element-carousel > .ui.icon.button.fullview > .icon {
	border-radius:400em;
	width: 44px;
	height: 44px;
	padding: 15px;
	background-color: hsla(0, 0%, 100%, 0.75);
}

element-carousel > .ui.icon.button.fullview > .icon:hover {
	background-color:white;
}

@media screen and (max-width: 768px) {
	element-carousel-cell {
		width: 100% !important;
	}
}

/* Fade CSS */

element-carousel.fade > .flickity-viewport > .flickity-slider {
	transform: none !important;
	left:0 !important;
}

element-carousel.fade element-carousel-cell {
	left: 0 !important;
	opacity: 0;
	transition: opacity 0.6s ease-in-out 0.3s;
	z-index: 1;
}

element-carousel.fade element-carousel-cell.is-selected {
	opacity: 1;
	transition: opacity 0.6s ease-in-out;
	z-index: 2;
}

element-carousel.fade > .flickity-prev-next-button {
	z-index: 3;
}
