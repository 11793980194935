fieldset.plain {
	border-width:0;
	padding-right:0;
	padding-left:0;
}
textarea::-webkit-selection,
input::-webkit-selection {
	background-color: rgba(100, 100, 100, 0.4);
	color: rgba(0, 0, 0, 0.87);
}
textarea::-moz-selection,
input::-moz-selection {
	background-color: rgba(100, 100, 100, 0.4);
	color: rgba(0, 0, 0, 0.87);
}
textarea::selection,
input::selection {
	background-color: rgba(100, 100, 100, 0.4);
	color: rgba(0, 0, 0, 0.87);
}

.ui.checkbox input + label[for] {
	cursor:pointer;
}

element-select {
	z-index:1; /* or else dropdown menu goes under what's next */
}

form[data-authfield] button[type="submit"]:before {
	content:'🔒';
}

[contenteditable] .ui.form .ui.message {
	display:block;
}

[contenteditable] [block-type="fetch"] .ui.message {
	display:block !important;
}

[contenteditable] .ui.form .field.hidden {
	display: block !important;
}
