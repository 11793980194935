element-gallery {
	display:block;
	position:relative;
}

element-gallery > [block-type] {
	display:none;
}

element-gallery[selected-mode="medialist"] > [block-type="medialist"],
element-gallery[selected-mode="portfolio"] > [block-type="portfolio"],
element-gallery[selected-mode="carousel"] > [block-type="carousel"] {
	display:block;
}

a.itemlink {
	display: block;
	width:100%;
	height:100%;
	text-align: center;
	background-position: center center;
	background-repeat: no-repeat;
}

element-portfolio-item:hover a.itemlink,
element-carousel-item:hover a.itemlink {
	display:block;
}
