/*
computeGutters is used once to get those numbers
for n=4 gutter is 0.75, n=3 gutter is 1, n=2 gutter is 1.5
square computeGutters(1.5, 1.5, 1, 4)
tall   computeGutters(1.5, 1.5, 1.29897, 4)
wide   computeGutters(1.5, 1.5, 0.62887, 3)

p, q are initial x, y gutters for n=2
	f is any h/w ratio one wants to impose on the grid cell

function computeGutters(p, q, f, n) {
	var s = 100*f - 2*f*p + q;
	var t = 100 - p;
	var u = 50 - p;
	var a = s / u;
	var b = f * u / t;
	var c = s / t;
	var P = (100 * (2*c - a)) / (n * (c - a));
	var Q = (100 * (2*c*f - a*c)) / (n * (c - a));
	var w = (100 / n) - P;
	var h = f * w;
	var W = 2*w + P;
	var H = 2*h + Q;
	var obj = {
		gx: P,
		gy: Q,
		pw: w,
		pW: W,
		ph: h,
		pH: H,
		w: n*w,
		W: n*W,
		h: n*h,
		H: n*H
	};
	for (var k in obj) obj[k] = Math.round(obj[k] * 10000) / 10000;
	return obj;
}
*/
element-portfolio {
	display:block; /* mandatory for custom elements */
	position:relative;
	margin-right:-0.75%;
	margin-bottom:-0.75%;
	overflow:hidden;
	min-height:2em;
}
element-portfolio > .cells {
/*	height:auto !important;*/
}
element-portfolio-item {
	display:block;
	position:relative;
	overflow:hidden;
	margin-right:0.75%;
	margin-bottom:0.75%;
	width:24.25%;
	padding-bottom:24.25%;
	background-color:#EEE; /* we need to see the item has the right dimensions */
}
element-portfolio-item[data-scale-width="2"] {
	width:49.25%;
}
element-portfolio-item[data-scale-height="2"] {
	padding-bottom:49.25%;
}

element-portfolio[data-shape="tall"] element-portfolio-item {
	padding-bottom:31.50%;
}
element-portfolio[data-shape="tall"] element-portfolio-item[data-scale-height="2"] {
	padding-bottom:63.75%;
}

element-portfolio[data-shape="wide"] {
	margin-right:-1%;
	margin-bottom:-1%;
}
element-portfolio[data-shape="wide"] element-portfolio-item {
	width:32.3333%;
	padding-bottom:20.3333%;
	margin-right:1%;
	margin-bottom:1%;
}
element-portfolio[data-shape="wide"] element-portfolio-item[data-scale-width="2"] {
	width:65.6666%;
}
element-portfolio[data-shape="wide"] element-portfolio-item[data-scale-height="2"] {
	padding-bottom:41.6666%;
}

element-portfolio[data-shape="small"] {
	margin-right:-0.375%;
	margin-bottom:-0.375%;
}
element-portfolio[data-shape="small"] element-portfolio-item {
	width:12.125%;
	padding-bottom:12.125%;
	margin-right:0.375%;
	margin-bottom:0.375%;
}
element-portfolio[data-shape="small"] element-portfolio-item[data-scale-width="2"] {
	width:24.625%;
}
element-portfolio[data-shape="small"] element-portfolio-item[data-scale-height="2"] {
	padding-bottom:24.625%;
}


@media screen and (max-width: 768px) {
	element-portfolio {
		margin-right:-1%;
		margin-bottom:-1%;
	}
	element-portfolio-item {
		width:32.3333%;
		padding-bottom:32.3333%;
		margin-right:1%;
		margin-bottom:1%;
	}
	element-portfolio-item[data-scale-width="2"] {
		width:65.6666%;
	}
	element-portfolio-item[data-scale-height="2"] {
		padding-bottom:65.6666%;
	}
	element-portfolio[data-shape="tall"] element-portfolio-item {
		padding-bottom:42.00%;
	}
	element-portfolio[data-shape="tall"] element-portfolio-item[data-scale-height="2"] {
		padding-bottom:85.00%;
	}
	element-portfolio[data-shape="small"] {
		margin-right:-0.75%;
		margin-bottom:-0.75%;
	}
	element-portfolio[data-shape="small"] element-portfolio-item {
		width:24.25%;
		padding-bottom:24.25%;
		margin-right:0.75%;
		margin-bottom:0.75%;
	}
	element-portfolio[data-shape="small"] element-portfolio-item[data-scale-width="2"] {
		width:49.25%;
	}
	element-portfolio[data-shape="small"] element-portfolio-item[data-scale-height="2"] {
		padding-bottom:49.25%;
	}
}

@media screen and (max-width: 512px) {
	element-portfolio-item,
	element-portfolio[data-shape="wide"] element-portfolio-item {
		width:100%;
		padding-bottom: 66.6666%;
	}
	element-portfolio-item[data-scale-width="2"],
	element-portfolio[data-shape="wide"] element-portfolio-item[data-scale-width="2"] {
		width:100%;
		padding-bottom: 66.6666%;
	}
}

@media screen and (max-width: 320px) {
	element-portfolio[data-shape="tall"] element-portfolio-item,
	element-portfolio[data-shape="small"] element-portfolio-item {
		width:100%;
		padding-bottom: 66.6666%;
	}
	element-portfolio[data-shape="small"] element-portfolio-item[data-scale-width="2"],
	element-portfolio[data-shape="tall"] element-portfolio-item[data-scale-width="2"] {
		width:100%;
		padding-bottom: 66.6666%;
	}
}

element-portfolio-item > .media,
element-portfolio-item > .content {
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	overflow:hidden;
}

element-portfolio-item:hover > .content {
	background-color:rgba(0, 0, 0, 0.5);
	color:white;
}

.ProseMirror element-portfolio-item > .content {
	pointer-events:none;
}
.ProseMirror element-portfolio-item > .content > * {
	pointer-events:auto;
}
