/* remove this comment *//*general*/
html, body {
	font-size: 13px;
	font-family: 'HelveticaNeue', Arial, Helvetica, sans-serif;
	line-height:1em;
	background:#fff;
}
a {
	color:#555;
}
a:hover {
	color:#111;
}
a:hover img {
	opacity:0.5;
}
h1 {
	font-size:24px;
}
h2 {
	font-size:19px;
}
h3 {
	font-size:17px;
}
h4 {
	font-size:14px;
}
h1, h2, h3, h4, h5, h6 {
	letter-spacing:1px;
	margin-top:0;
	margin-bottom:0;
	font-family: 'HelveticaNeue', Arial, Helvetica, sans-serif;
	color:#111;
}
.inverted.layout h1, .inverted.layout h2, .inverted.layout h3, .inverted.layout h4, .inverted.layout h5, .inverted.layout h6 {
	color:#fff;
}


h1 strong, h2 strong, h3 strong, h4 strong {
	font-family: 'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;
}
h1 .lighter, h2 .lighter, h3 .lighter, h4 .lighter {
	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
}
p strong {
	font-family: 'HelveticaNeue-Bold', Arial, Helvetica, sans-serif;
}
p .lighter,
span[block-type="light"] {
	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
}
p {
	color: #333;
}
span.color {
	color:#fff;
}
p.justify.aligned {
	padding-right: 1em;
}
p.justify.aligned:last-child {
	padding-right: 0;
}
/*end general*/





/*menus*/
.ui.menu {
	border: none;
	box-shadow: none;
	margin: 1rem 2rem;
	background:transparent;
	font-family:'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;
}
.ui.menu .ui.dropdown.item, .ui.menu .item {
	font-family:'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;
	/*	text-transform:uppercase;*/
	background: none !important;
	color:#555;
}
.ui.menu .ui.dropdown.item > .menu {
	background:rgba(55, 55, 55, 0.7) !important;
	padding:0.75em;
	border: 0;
	border-radius: 0;
}
.ui.menu .ui.dropdown .menu > .item:hover, .ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover {
	color:#111 !important;
	background:transparent !important;
}
.ui.menu .ui.dropdown .menu > .item {
	text-transform:uppercase !important;
	letter-spacing:1px;
	line-height: 1em;
	padding: 0.25em 0.5em !important;
	color:#555 !important;
}
.ui.menu .item:before {
	width: 0;
}
.ui.menu .ui.dropdown .menu > .active.item {
	background:transparent !important;
	color:#333 !important;
}
.ui.menu .ui.dropdown .menu > .active.item:hover {
	color:#111 !important;
}

header .ui.menu .ui.simple.dropdown.item:nth-child(4) .menu {
	right:0rem;
	left:-21rem;
	overflow:hidden;
}
header .ui.menu .ui.simple.dropdown.item:nth-child(5) .menu {
	right:-1rem;
	left:-17rem;
	overflow:hidden;
}
/*end menu*/



/*header*/
[block-type="header"] {
	background:#fff;
}

header .ui.grid {
	transition: all 0.4s ease-in-out 0s;
	padding-top:1em;
}
element-sticky:not([data-mode="start"]) .ui.grid {
	padding-top:0em;
}
header .ui.grid p a {
	padding-left: 2em;
	line-height: 2em;
	font-size:17px;
	letter-spacing:3px;
	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
	color:#ccc;
}
element-sticky:not([data-mode="start"]) > .variant-h > .ui.grid {
	background:rgba(255, 255, 255, 0.4);
}
element-sticky[data-collapsed="false"].header {
	margin-bottom:1em;
	background-color:rgba(255, 255, 255, 0.9);
}
element-sticky[data-collapsed="true"].header {
	margin-bottom:0;
	background-color:rgba(255, 255, 255, 0.3);
}
element-sticky.header .column {
	margin-top:0.25em;
	padding-right:0rem !important;
}
header .ui.grid .five.wide.column {
	margin-left:1.5rem;
	margin-top:0rem;
}

header .ui.grid > .column:not(.row) {
	margin-top:0rem;
}



element-sticky.header .column .layout.fullwidth {
	height:1em !important;
}

@media (max-width: 991px) {
	header .ui.grid p a {
		padding-left:1em;
	}
}

@media (max-width: 767px) {
	element-sticky.header .ui.stackable.grid > .column:not(.row) {
		padding-bottom:0 !important;
		padding-right: 0 !important;
	}
	header .ui.grid p a {
		padding-left:0;
	}
	header .ui.grid > .column:not(.row) {
		padding: 0 1rem !important;
	}
	header .ui.grid {
		margin-bottom:2em;
	}
	.ui.menu .ui.dropdown .menu > .item {
		line-height:2em;
	}
	header .ui.grid .layout.right {
		justify-content: flex-start;
	}
	header .ui.grid .layout.right .ui.menu {
		margin:0;
	}
	element-sticky.header .column {
		margin-top:0;
	}
}
/*end header*/



/*button*/
.ui.button {
	background:transparent;
	font-family:'HelveticaNeue', Arial, Helvetica, sans-serif;
	color:#999;
	border:1px solid #999;
	border-radius:0;
}
.ui.button:hover {
	color:#fff;
	background:#555;
	border:1px solid #555;
}
.ui.button:active, .ui.button:focus {
	color:#fff;
	background:#555;
	border:1px solid #555;
}
/*end button*/




/*sitemap*/
.ui.list[block-type="sitemap"] .list > .item {
	margin-left: 2em !important;
}
element-sitepage {
	padding-left:5px;
}
element-sitepage .caret-icon {
	padding-right:2rem !important;
}
.ui.accordion .title:not(.ui) {
	color:#fff;
	font-family:'HelveticaNeue', Arial, Helvetica, sans-serif;
}
[block-type="sitemap"] .list > .item > .content, [block-type="sitemap"] > .item > .content {
	box-shadow:none;
	border:1px solid #555;
}
/*end sitemap*/


/*form*/
.ui.form .field > label {
	color:#fff;
}
.ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"], .ui.form .success.message, .ui.form .warning.message, .ui.form .error.message {
	/*	border:none;*/
	/*	background:#555;*/
	border-radius:0;
	color:#ccc;
	box-shadow:none;
}
.ui.form textarea {
	border-radius:0;
}

[block-type="mail_body"] table.container {
	background:transparent;
}

/*end form*/

/*carousel*/
element-carousel-cell h1 {
	font-size:3rem;
	color:#111;
	margin:1rem;
	margin-bottom:2rem;
}
element-carousel-cell h2 {
	font-size:2rem;
	color:#111;
}
element-carousel-cell .ui.button {
	background:transparent;
	font-family:'HelveticaNeue', Arial, Helvetica, sans-serif;
	color:#333;
	border:1px solid #333;
	border-radius:0;
}
element-carousel-cell {
	background:#fff;
}
/*end carousel*/





/*video*/
/*.ui.embed iframe, .ui.embed embed, .ui.embed object {*/
/*	height:100vh;*/
/*}*/
element-embed {
	width:100%;
}

element-carousel-cell .ui.embed iframe body {
	background:#000 !important;
}
/*video*/







/*home carousel*/


[block-type="main"] > .ui.container.layout > element-carousel element-carousel-cell {
	height:280px;
}
[block-type="main"] > .ui.container.layout > element-carousel {
	margin-bottom:1em;
	height:280px;
}

element-carousel a.itemlink > p {
	transform: translate(0, 1em);
	transition:0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
	opacity:0;
	transition-duration:0.5s;
}

element-carousel a.itemlink:hover > p,
element-carousel a.itemlink[block-focused] > p {
	transform: translateY(0);
	transition-duration: 1s;
	opacity:1;
}


element-carousel a.itemlink > *[block-type="heading"] {
	transform: translateY(2em);
	transition:0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
	transition-duration:2s;
}

element-carousel a.itemlink:hover > *[block-type="heading"],
element-carousel a.itemlink[block-focused] > *[block-type="heading"] {
	transform: translateY(0);
	transition-duration: 2s;
}



element-carousel element-carousel-cell a.itemlink {
	/*	padding-left:3em;*/
	/*	padding-right:3em;*/
	/*	margin-top:195px;*/
	width:100%;
	height:auto;
	overflow:hidden;
	padding-bottom:10px;
}
element-carousel element-carousel-cell a.itemlink > *[block-type="heading"] {
	margin-bottom: 0.5rem;
	font-family: 'HelveticaNeue-Light', Helevtica, Arial, sans-serif;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size: 19px;
	color: #fff;
	min-height:30px;
	text-align:left;
	opacity:0.9;
	padding-top:190px;
}

element-carousel element-carousel-cell a.itemlink > p {
	font-family: 'HelveticaNeue', Helevtica, Arial, sans-serif;
	color:#fff;
	font-size:15px;
	text-transform: none;
	line-height:20px;
	margin:0 1rem;
	margin-top:15px;
}
element-carousel element-carousel-cell a.itemlink > p > span {
	opacity:0.9;
}
element-carousel element-carousel-cell a.itemlink:hover,
element-carousel element-carousel-cell a.itemlink[block-focused] {
	color: #fff;
}
element-carousel element-carousel-cell a.itemlink p.left.aligned {
	text-align:left;
}
element-carousel element-carousel-cell a.itemlink > h1.right.aligned {
	text-align:right !important;
}





/*end home carousel*/




/*footer*/


/*end footer*/


/*portfolio films*/
.ui.tab.segment {
	margin:0;
	padding:0;
	box-shadow:none;
	-webkit-box-shadow:none;
	border:none;
}
.ui.tab.segment element-portfolio-item .content {
	opacity:0;
}
.ui.tab.segment element-portfolio-item:hover .content {
	opacity:1;
}
.ui.tab.segment element-portfolio-item:hover > .content {
	background-color:transparent;
}
.ui.tab.segment element-portfolio-item:hover > .content .layout {
	background-color:rgba(255, 255, 255, 0.5);
}
.ui.tab.segment element-portfolio-item .content .layout p {
	color:#111;
}
.ui.tab.segment element-portfolio-item .content .layout .ui.button {
	border:none;
	color:#111;
}
.ui.tab.segment element-portfolio-item .content .layout .ui.button:hover,
.ui.tab.segment element-portfolio-item .content .layout .ui.button:active {
	border:none;
	color:#111;
	background:#fff;
}
.ui.tab.segment element-portfolio-item:hover > .content .inverted.layout {
	background-color:rgba(0, 0, 0, 0.5);
}
.ui.tab.segment element-portfolio-item .content .inverted.layout p {
	color:#fff;
}
.ui.tab.segment element-portfolio-item .content .inverted.layout .ui.button {
	border:none;
	color:#fff;
	width:100%;
	height:100%;
	font-size:16px;
}
.ui.tab.segment element-portfolio-item .content .inverted.layout .ui.button:hover,
.ui.tab.segment element-portfolio-item .content .inverted.layout .ui.button:active {
	border:none;
	color:#fff;
	background:none;
}
.ui.tab.segment element-portfolio-item .content .inverted.layout .ui.form {
	width:100%;
	height:100%;
}
.ui.tab.segment element-portfolio-item .content .inverted.layout .ui.form > div {
	display:contents;
}
.ui.tab.segment element-portfolio-item .content .inverted.layout .ui.button strong {
	display: block;
	line-height: 32px;
}












element-portfolio-item .content {
	opacity:0;
}
element-portfolio-item:hover .content {
	opacity:1;
}
element-portfolio-item:hover > .content {
	background-color:transparent;
}
element-portfolio-item:hover > .content .layout {
	background-color:rgba(255, 255, 255, 0.5);
}
element-portfolio-item .content .layout p {
	color:#111;
}
element-portfolio-item .content .layout .ui.button {
	border:none;
	color:#111;
}
element-portfolio-item .content .layout .ui.button:hover,
element-portfolio-item .content .layout .ui.button:active {
	border:none;
	color:#111;
	background:#fff;
}
element-portfolio-item:hover > .content .inverted.layout {
	background-color:rgba(0, 0, 0, 0.5);
}
element-portfolio-item .content .inverted.layout p {
	color:#fff;
}
element-portfolio-item .content .inverted.layout .ui.button {
	border:none;
	color:#fff;
	width:100%;
	height:100%;
	font-size:16px;
}
element-portfolio-item .content .inverted.layout .ui.button:hover,
element-portfolio-item .content .inverted.layout .ui.button:active {
	border:none;
	color:#fff;
	background:none;
}
element-portfolio-item .content .inverted.layout .ui.form {
	width:100%;
	height:100%;
}
element-portfolio-item .content .inverted.layout .ui.form > div {
	display:contents;
}
element-portfolio-item .content .inverted.layout .ui.button strong {
	display: block;
	line-height: 32px;
}


.ui.attached.tabular.menu {
	display:none;
}









/*end portfolio films*/


/*menu*/
.ui.menu:first-child {
	margin-top:1rem;
}
/*end menu*/





@media (max-width: 512px) {
	.ui.secondary.pointing.menu .item {
		font-size:12px;
		padding:0.85714286em 0.7em;
	}
}
