/* async progress bar and error signal */

body:after {
	content: '';
	position:fixed;
	display:block;
	left:0;
	top:1px;
	width:0%;
	height:0px;
	opacity:0.7;
	background: #4486cc;
	box-shadow: 0 0 5px rgba(99,162,235,0.7);
	z-index:1000;
}
html[data-stage] > body:after {
	transition-property:width;
	transition-duration:1s;
	transition-timing-function:linear;
}
html[data-stage="init"] > body:after,
html[data-stage="ready"] > body:after,
html[data-stage="setup"] > body:after,
html[data-stage="error"] > body:after {
	height:2px;
}

html[data-stage="init"] > body:after {
	width:50%;
}
html[data-stage="ready"] > body:after,
html[data-stage="setup"] > body:after {
	width:100%;
}
html[data-stage="error"] > body:after {
	display:block;
	width:100%;
	background:#cc3333;
}

html.transition > body:after {
	display:none !important;
}

/* transitions */
html.transition,
html.transition > body.transition,
html.transition > body.transition-before {
	overflow:hidden !important;
}
.transition-before > .transition-from[block-type="main"],
.transition > .transition-from[block-type="main"] {
	position:absolute;
	height:100%;
	width:100%;
}
.transition-before > .transition-from:not([block-type="main"]),
.transition > .transition-from:not([block-type="main"]) {
	display: none !important;
}

.transition > .transition-to:not([block-type="main"]) {
	visibility:hidden !important;
}
.transition-before > .transition-to {
	visibility:hidden;
}

/* translate transitions */
.transition.up-from > .transition-from[block-type="main"],
.transition.down-from > .transition-from[block-type="main"],
.transition.right-from > .transition-from[block-type="main"],
.transition.left-from > .transition-from[block-type="main"] {
	transform: translate3d(0, 0, 0);
	z-index:1;
}
.transition.up-from > .transition-to[block-type="main"],
.transition.down-from > .transition-to[block-type="main"],
.transition.right-from > .transition-to[block-type="main"],
.transition.left-from > .transition-to[block-type="main"] {
	z-index:0;
}

.transition.transitioning.up-from > .transition-from[block-type="main"] {
	transition: transform 1.5s ease-in;
	transform: translate3d(0, -100%, 0);
}
.transition.transitioning.down-from > .transition-from[block-type="main"] {
	transition: transform 1.5s ease-in;
	transform: translate3d(0, 100%, 0);
}
.transition.transitioning.left-from > .transition-from[block-type="main"] {
	transition: transform 1.5s ease-in;
	transform: translate3d(-100%, 0, 0);
}
.transition.transitioning.right-from > .transition-from[block-type="main"] {
	transition: transform 1.5s ease-in;
	transform: translate3d(100%, 0, 0);
}

.transition.up-to > .transition-to[block-type="main"],
.transition.down-to > .transition-to[block-type="main"],
.transition.right-to > .transition-to[block-type="main"],
.transition.left-to > .transition-to[block-type="main"] {
	z-index:1;
}
.transition.up-to > .transition-to[block-type="main"] {
	transform: translate3d(0, -100%, 0);
}
.transition.down-to > .transition-to[block-type="main"] {
	transform: translate3d(0, 100%, 0);
}
.transition.left-to > .transition-to[block-type="main"] {
	transform: translate3d(-100%, 0, 0);
}
.transition.right-to > .transition-to[block-type="main"] {
	transform: translate3d(100%, 0, 0);
}
.transition.up-to > .transition-from[block-type="main"],
.transition.down-to > .transition-from[block-type="main"],
.transition.right-to > .transition-from[block-type="main"],
.transition.left-to > .transition-from[block-type="main"] {
	z-index:0;
}

.transition.transitioning.up-to > .transition-to[block-type="main"],
.transition.transitioning.down-to > .transition-to[block-type="main"],
.transition.transitioning.left-to > .transition-to[block-type="main"],
.transition.transitioning.right-to > .transition-to[block-type="main"] {
	transition: transform 1.5s ease-in;
	transform: translate3d(0, 0, 0) !important;
}

/* opacity transitions */
.transition.fade-to > .transition-to,
.transition.fade-to > .transition-from,
.transition.fade-from > .transition-to,
.transition.fade-from > .transition-from {
	transform: translate3d(0, 0, 0) !important;
}
.transition.fade-to > .transition-to[block-type="main"] {
	z-index:1;
	opacity:0;
	transition: opacity 1.5s ease-in;
	transform: translate3d(0, 0, 0) !important;
	height:100% !important;
}
.transition.transitioning.fade-to > .transition-to[block-type="main"] {
	opacity:1;
}

.transition.fade-from > .transition-from[block-type="main"] {
	z-index:1;
	opacity:1;
	transition: opacity 1.5s ease-in;
	height:100% !important;
}
.transition.transitioning.fade-from > .transition-from[block-type="main"] {
	opacity:0;
}

