element-template {
	display:block;
	position:relative;
}

element-template > [block-content="template"] {
	display:none;
}

[contenteditable] element-template > [block-content="template"] {
	display:block;
	min-height:1em;
}

[contenteditable] element-template > .view {
	display:none;
}
[block-id][block-type="binding"][data-label] {
	letter-spacing:-9999px;
	word-spacing:-9999px;
}
[block-id][block-type="binding"][data-label]:after {
	content:"[" attr(data-label) "]";
	letter-spacing:normal;
	word-spacing:normal;
}

