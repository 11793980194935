.dropdown > .menu > .item .ui.image {
	max-width:none; /* workaround image width not being accounted for */
}

.ui.dropdown:hover > .caret-icon:before {
	transform:rotate(90deg);
}

[block-type="menu_item_popup"] {
	display:block !important;
	position:static !important;
}
[block-type="menu_item_popup"] > .popup {
	display:none;
	position: absolute;
	z-index: 1900;
	margin-top: 0.928571em;
	width:90%;
	left:5%;
	max-width:none;
	min-width: -webkit-min-content;
	min-width: -moz-min-content;
	min-width: min-content;
	padding: 0.833em 1em;
}
[block-type="menu_item_popup"] > .popup > div {
	border: 1px solid #D4D4D5;
	background: #FFFFFF;
	padding: 0.833em 1em;
	font-weight: normal;
	font-style: normal;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
	max-height:60vh; /* this assumes the popup starts from the top */
	overflow-y:auto;
}
[block-type="menu_item_popup"]:focus > .popup,
[block-type="menu_item_popup"]:hover > .popup,
[block-type="menu_item_popup"][block-focused] > .popup {
	display:block;
}

